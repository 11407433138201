main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quote,
.about {
  width: 70vw;
  margin: 0 auto;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.about {
  text-align: left;
}

.joke {
  border-left: 5px solid var(--color-dim-white);
  padding: 0.5rem 1rem;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  .quote,
  .about {
    font-size: 1.8rem;
    width: 95vw;
  }

  .joke {
    padding: 0;
    border: 0;
  }
}

.meta {
  font-size: 1.4rem;
}

.refresh-quote {
  font-family: monospace;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 2.8rem;
  padding: 0.8rem 2rem;
  color: var(--color-white);
  border: 1px;
  background-color: var(--color-black);
  filter: brightness(1.8);
}

.refresh-quote:hover {
  filter: brightness(2.2);
  cursor: pointer;
}

.refresh-quote:focus {
  outline: 1px solid var(--color-light-blue);
}

@media only screen and (max-width: 600px) {
  .quote {
    font-size: 1.8rem;
    width: 95vw;
  }

  .meta {
    font-size: 1.2rem;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  padding: 1rem;
}

@media only screen and (max-width: 600px) {
  footer {
    background-color: var(--color-near-black);
    filter: brightness(1.3);
    font-size: 1.1rem;
    line-height: 1.4;
    letter-spacing: -0.8px;
  }
  footer > div {
    flex-grow: 0;
  }
}
