/* https://coolors.co/d8dbe2-a9bcd0-58a4b0-373f51-1b1b1e */

:root {
  --color-white: #d8dbe2;
  --color-dim-white: hsl(222, 15%, 20%);
  --color-black: #1b1b1e;
  --color-dark-black: #0c0c0e;
  --color-near-black: #050506;
  --color-light-blue: #a9bcd0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: monospace;
}

article {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  text-align: center;
  background-color: var(--color-black);
  color: var(--color-white);
}

a {
  color: var(--color-light-blue);
}

main {
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.home footer {
  padding: 1rem;
  font-size: 1rem;
  display: block;
}
